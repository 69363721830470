import React from 'react';
import Img from 'gatsby-image';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { Container, Grid } from '@material-ui/core';
import { ParallaxBanner } from 'react-scroll-parallax';
import CompareIcon from '@material-ui/icons/CompareArrows';
import UpdateIcon from '@material-ui/icons/Update';
import PriceIcon from '@material-ui/icons/LocalOffer';
import BypassIcon from '@material-ui/icons/Rotate90DegreesCcw';
import FeatureSectionLight from '../components/featureSectionLight';
import SubLayout from '../layouts/subLayout';
import ReactIcon from '../../assets/svg/react-original.svg';
import WorkboxIcon from '../../assets/svg/workbox.svg';
import NextIcon from '../../assets/svg/nextjs-plain.svg';
import AllServices from '../components/allServices';
import SEO from '../components/seo';

const PWAPage = () => {
  const data = useStaticQuery(graphql`
        query PWAImages {
            pwa: file(
                relativePath: {
                    eq: "aplikacje-progresywne-pwa.png"
                }
            ) {
                id
                childImageSharp {
                    fluid(quality: 95, traceSVG: { threshold: 22 }) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            pwaMain: file(
                relativePath: {
                    eq: "aplikacje-progresywne-pwa.png"
                }
            ) {
                id
                childImageSharp {
                    fluid(quality: 95, traceSVG: { threshold: 22 }) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            kompleksowaPWA: file(
                relativePath: {
                    eq: "kompleksowa-usluga.png"
                }
            ) {
                id
                childImageSharp {
                    fluid(quality: 95, traceSVG: { threshold: 22 }) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `);
  return (
    <SubLayout
      title={(
        <>
          Aplikacje
          {' '}
          <span>Progresywne</span>
        </>
)}
      img="/img/apps-mobile-bg.jpg"
      subtitle="Nowoczesna alternatywa dla aplikacji mobilnych"
    >
      <SEO title="Progresywne Aplikacje Webowe PWA - iqcode Software House" description="Tworzymy wieloplatformowe i dostępne aplikacje progresywne (PWA) stanowiące nowoczesną alternatywę dla aplikacji mobilnych." />
      <div style={{ boxShadow: '3px 3px 30px #00000070' }}>
        <FeatureSectionLight
          title={(
            <>
              Między
              {' '}
              Aplikacjami
              {' '}
              <span>Mobilnymi</span>
              {' '}
              a
              {' '}
              Stronami
              {' '}
              <span>Internetowymi</span>
            </>
                      )}
          img={<div style={{ padding: '0 14%' }}><Img alt="progresywne aplikacje webowe" fluid={data.pwaMain.childImageSharp.fluid} /></div>}
          technologies={[
            { icon: <ReactIcon />, name: 'React.js' },
            { icon: <WorkboxIcon />, name: 'Workbox' },
          ]}
          desc={(
            <>
              <p>
                Progresywne aplikacje webowe (ang.
                {' '}
                <i>Progressive Web Apps</i>
                , PWA) to nowy rodzaj produktów cyfrowych stanowiących alternatywę dla klasycznych aplikacji mobilnych. Choć aplikacje progresywne działają wewnątrz przeglądarki internetowej, umożliwiają instalację na urządzeniu użytkownika i uruchamianie w trybie pełnoekranowym oferując przy tym user experience zbliżone do aplikacji natywnych.
              </p>
              <p>
                Aplikacje progresywne powstały w odpowiedzi na coraz większą powściągliwość użytkowników przy pobieraniu aplikacji mobilnych ze sklepów App Store oraz Play Store. Ponieważ aplikacje PWA dystrybuowane są za pomocą linku, pozwalają na istotne
                {' '}
                <strong>obniżenie progu wejścia użytkownika</strong>
                {' '}
                do aplikacji przy zachowaniu funkcjonalności charakterystycznych dla klasycznych aplikacji mobilnych.
              </p>
            </>
                      )}
        />
      </div>
      <div className="section" id="aplikacje-mobilne">
        <ParallaxBanner
          className="phone-showcase parallax-container bg-dark overlay-holder"
          style={{ height: 'auto', backgroundColor: '#b921cb4d' }}
          layers={[{
            image: '/img/p3.jpg',
            amount: 0.4,
            props: { style: { zIndex: -1 } },
          }]}
        >
          <div className="parallax" />
          <Container>
            <div className="row">
              <div className="col m12 l4">
                <div className="phone-block">
                  <CompareIcon />
                  <h4>Wieloplatformowość</h4>
                  <p>

                    Ten sam produkt umożliwia instalację na smartfonach z systemem Android oraz iOS, jak i komputerach i laptopach.

                  </p>
                </div>
                <div className="phone-block">
                  <UpdateIcon />
                  <h4>Szybsze wdrażanie</h4>
                  <p>
                    Wypuszczanie aktualizacji PWA nie generuje opóźnień związanych z procesem zatwierdzenia aplikacji przez operatora sklepu.
                  </p>
                </div>
              </div>
              <div className="col m12 l4">
                <Img fluid={data.pwa.childImageSharp.fluid} alt="progresywne aplikacje webowe" />
              </div>
              <div className="col m12 l4">
                <div className="phone-block">
                  <PriceIcon />
                  <h4>Niższe koszty produkcji</h4>
                  <p>
                    Produkcja PWA jest mniej pracochłonna niż aplikacji natywnej o tej samej funkcjonalności.
                  </p>
                </div>
                <div className="phone-block">
                  <BypassIcon />
                  <h4>Ominięcie prowizji sklepu</h4>
                  <p>
                    Transakcje dokonywane wewnątrz aplikacji progresywnej nie są obłożone prowizjami sklepów z aplikacjami.
                  </p>
                </div>
              </div>
            </div>
          </Container>
          <div className="diagonal-overlay-color" />
        </ParallaxBanner>
        <div className="technologies bg-dark outline-fix z-depth-3">
          <Container>
            <h3>
              <span>Technologie</span>
              {' '}
              których używamy
            </h3>

            <Grid container>
              <Grid className="tech-cont" item xs={12} sm={12} md={4}>
                <ReactIcon className="tech-icon" />
                <p>React.js</p>
              </Grid>
              <Grid className="tech-cont" item xs={12} sm={12} md={4}>
                <WorkboxIcon className="tech-icon" />
                <p>Workbox</p>
              </Grid>
              <Grid className="tech-cont" item xs={12} sm={12} md={4}>
                <NextIcon className="tech-icon" />
                <p>Next.js</p>
              </Grid>
            </Grid>
            <Link className="btn btn-alt" to="/technologie">Więcej informacji</Link>
          </Container>
        </div>

        <FeatureSectionLight
          title={(
            <>
              <span>Kompleksowa </span>
              Usługa
            </>
                  )}
          mb0
          img={(
            <div style={{ padding: '10%' }}>
              <Img
                alt="kompleksowa usluga"
                fluid={data.kompleksowaPWA.childImageSharp.fluid}
                imgStyle={{ width: 'auto' }}
              />
            </div>
                  )}
          desc={(
            <>
              <p>
                Niemal każda aplikacja progresywna,
                dla zapewnienia pożądanej
                funkcjonalności, wymaga odpowiednio
                przygotowanego oprogramowania
                działajacego po stronie serwera
                (backendu).
              </p>
              <p>
                Oferujemy kompleksową realizację projektów informatycznych począwszy od projektowania oprogramowania i inżynierii wymagań, poprzez prace programistyczne i doradztwo projektowe związane z debiutem rynkowym aplikacji, a zakończywszy na wsparciu technicznym, analityce i ciągłym rozwoju produktu cyfrowego.
                Ponieważ dysponujemy szerokim wachlarzem technologii backendowych, mobilnych i webowych, możemy realizować złożone projekty programistyczne łączące wiele dziedzin IT.
              </p>
            </>
                  )}
          link="Porozmawiajmy"
          to="/kontakt/"
        />

        <AllServices />
      </div>
    </SubLayout>
  );
};

export default PWAPage;
